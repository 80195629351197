/* Languange EN */

var enNavBar = {
    
    //Account
    "navBar.account.myAccount"          : "My Account",
    "navBar.account.celebAccount"       : "Celeb Account",
    "navBar.account.regis"              : "Sign Up",
    "navBar.account.halo"               : "Hello, ",
    "navBar.account.changeToCeleb"      : "Move to Celebrity Account",
    "navBar.account.changeToFans"       : "Move to Fans Account",

    //Misc
    "navBar.account.notif"              : "Notifications",
    "navBar.account.vcschedule"         : "Video Call Schedule",
    "navBar.account.invoiceCreation"    : "Invoice Creation",
    "navBar.account.registTitle"        : "SignUp as a fans",
    "navBar.account.registDesc"         : "to get more features from your idol celeb",

    //Notif
    "navBar.notif.title"                : "Belum ada notifikasi nih",
    "navBar.notif.desc"                 : "Kami akan update info-info terbaru dari BintanGO Silakan cek notifikasi kamu secara berkala",
    "navBar.notif.showAll"              : "Show All Notification",
    "navBar.notif.more"                 : "Show More",

    //Video Call
    "navBar.vc.title"                   : "Video Call Schedule",
    "navBar.creatorAccount"             : "CREATOR ACCOUNT"
    
  };
  
export default enNavBar;