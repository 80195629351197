/* Languange ID */

var idCreatorProfile = {  
    "index.topVerifiedCreator"          : "Dapatkan lebih banyak peluang dan manfaat dengan menjadi kreator yang  terverifikasi",
    "index.buttonTopVerifiedCreator"    : "VERIFY ME!",
    "index.modalTitleVerifiedCreator"   : "Be a verified creator and go beyond! 🚀",
    "index.modalDescVerifiedCreator"    : "You will never be ready, just start now💪🏻. One more step to get your creator freedom with BintanGO",
    "index.modalbuttonVerifiedCreator"    : "VERIFY ME",
    "index.modalbuttonLaterVerifiedCreator"    : "NO, MAYBE LATER",

    "sidebar.home"                  : "Beranda",
    "sidebar.dashboard"             : "Dashboard",
    "sidebar.brandDeals"            : "Jobs",
    "sidebar.monetization"          : "Monetization",
    "sidebar.financing"             : "Financing",
    "sidebar.analytics"             : "Analytics",
    "sidebar.mission"               : "Mission",
    "sidebar.invoice"               : "Invoice",
    "sidebar.myLink"                : "My Link",
    "sidebar.forum"                 : "Forum",
    "sidebar.fanWorld"              : "Fan World",
    "sidebar.profile"               : "Profile",
    "sidebar.personalInformation"   : "Personal Information",
    "sidebar.creatorProfile"        : "Creator Profile",
    "sidebar.accountSetting"        : "Account Setting",
    "sidebar.creatorSetting"        : "Creator Setting",
    "sidebar.bankAccount"           : "Bank Account",
    "sidebar.sosmed"                : "Social Media",
    "sidebar.accountSecurity"       : "Account Security",
    "sidebar.referral"              : "Referral",
    "sidebar.earning"               : "Earning",
    "sidebar.balance"               : "Balance",
    "sidebar.celebritySetting"      : "Celebrity Setting",
    "sidebar.myTask"                : "My Task",
    "sidebar.services"              : "Services",
    "sidebar.vacationMode"          : "Vacation Mode",
    "sidebar.backToHome"            : "Back to home",
    "sidebar.minimize"              : "Memperkecil",

    "creatorHome.titleWelcome"      : "Selamat datang di CreatorSpace",
    "creatorHome.descWelcome"       : "Disini semua orang bisa menjadi Creator, let’s grow beyond!",
    "creatorHome.titleBrandDeals"   : "Ada Kolaborasi baru untukmu!",
    "creatorHome.descBrandDeals"    : "Daftar sekarang sebelum quota habis!",
    "creatorHome.titleComment"      : "Deals tercepat dan termudah!",
    "creatorHome.descComment"       : "Comment + Share, selesai dalam 5 menit!",
    "creatorHome.titleJob"          : "Rekomendasi peluang khusus untukmu!",
    "creatorHome.descJob"           : "Dibuatkan secara khusus untuk kamu berdasarkan profilmu!",
    "creatorHome.balance"           : "Saldo",
    "creatorHome.points"            : "Poin",
    "creatorHome.redeem"            : "TUKARKAN",
    "creatorHome.infoDesc"          : "Jelajahi lebih banyak tool  dan fitur di aplikasi BintanGO\nUnduh sekarang untuk mendapatkan pengalaman terbaik dari BintanGO",
    "creatorHome.menuTitleMyLink"   : "Buat lamanmu sendiri",
    "creatorHome.menuDescMyLink"    : "Buat digital portofoliomu menggunakan BintanGO MyLink",
    "creatorHome.menuTitleMission"  : "Klaim misimu!",
    "creatorHome.menuDescMission"   : "Selesaikan misi, kumpulkan points dan tukarkan dengan berbagai hadiah menarik",
    "creatorHome.menuTitleInvoice"  : "Kirim invoice pertamamu",
    "creatorHome.menuDescInvoice"   : "Buat invoice secara instan & kirimkan dengan BintanGO",
    "creatorHome.menuTitleFinancing": "Daftar financing sekarang",
    "creatorHome.menuDescFinancing" : "Tidak perlu menunggu lama lagi, terima pembayaran lebih cepat dengan BintanGO!",
    "creatorHome.exclusiveForVerified" : "EKSLUSIF UNTUK VERIFIED CREATOR",

    "creatorHome.onBoardingOneTitle"    : "Informasi Profil/Informasi Creator",
    "creatorHome.onBoardingOneDesc"     : "Coba ceritain ke kita, biar kita ngerti",
    "creatorHome.onBoardingTwoTitle"    : "Hubungkan media sosial",
    "creatorHome.onBoardingTwoDesc"     : "Dapatkan insight medsosmu di satu tempat",
    "creatorHome.onBoardingThreeTitle"  : "Lokasi",
    "creatorHome.onBoardingThreeDesc"   : "Temukan peluang cuan terdekat denganmu",
    "creatorHome.onBoardingFourTitle"   : "Kategori",
    "creatorHome.onBoardingFourDesc"    : "Passionmu apa? dapatkan peluang sesuai kontenmu",

    "creatorHome.downloadBintagoTitle" : "Jelajahi BintanGO melalui Aplikasi",
    "creatorHome.downloadBintagoDesc" : "Peluang pekerjaan khusus lebih banyak, analitik yang terintegrasi, forum kreator dan lebih banyak lagi! Download BintanGO Hari ini!",
    "creatorHome.downloadBintagoGetTheApp" : "Download BintanGO",

    "downloadApps.title"                : "Fitur ini menunggumu di aplikasi",
    "downloadApps.desc"                 : "Lebih banyak peluang pekerjaan, analitik yang terintegrasi, forum kreator dan lebih banyak lagi! Cara tercepat dan terbaik untuk menjelajahi CreatorSpace, luncurkan petualangan BintanGO-mu hari ini!",

    "creatorDashboard.titleWelcome"      : "Creator Dashboard",
    "creatorDashboard.descWelcome"       : "Semua insights dan kebutuhan mu, sudah tersedia di dalam satu dashboard",

    "creatorBrandDeals.titleWelcome"      : "Brand Deals",
    "creatorBrandDeals.descWelcome"       : "Tempat terbaik untuk berkolaborasi dengan brand hebat, tanpa repot, dan mudah dilakukan.\nApply sekarang!",
    "creatorBrandDeals.stillCounting"     : "...dan masih banyak lagi",

    "creatorListJobs.titleWelcome"      : "Lorem Ipsum Init Condinental",
    "creatorListJobs.descWelcome"       : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor risus purus. ",

    "creatorHistoryJobs.titleWelcome"      : "Jobs History",
    "creatorHistoryJobs.descWelcome"       : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor risus purus. ",
    
    "creatorMonetization.titleWelcome"     : "💸 Monetization Tools for Creator",
    "creatorMonetization.descWelcome"      : "we give you a secret how to earn easy money. just a click and let the money work for you.",

    "postMonetization.successTitle" : "Yeay, success post it to your Instagram",
    "postMonetization.successDesc"  : "Go check to your post on your instagram apps or you can check other deals & earn more",
    "postMonetization.continue"     : "CONTINUE",

    "postMonetization.failedTitle"  : "Sorry, we can't post to your instagram",
    "postMonetization.failedDesc"   : "Somehow we can't post it to your instagram, let's try again",
    "postMonetization.tryAgain"     : "TRY AGAIN",
    "postMonetization.later"        : "LATER",
    "postMonetization.contactUs"    : "CONTACT US",

    "postMonetization.jobTitle"     : "Well done! You have done all jobs",
    "postMonetization.jobDesc"   : "Check again periodically to get the most updated jobs in buzz hunt!",
    "postMonetization.backToHome"   : "BACK TO HOME",
    "postMonetization.explore"      : "EXPLORE BRAND DEALS",

    "creatorAccount.category"       : "kategori",
    "creatorAccount.subCategory"    : "sub kategori",
    "creatorAccount.seeAll"         : "LIHAT SEMUA",
    "creatorAccount.menuCreatorInfo": "Info Kreator",
    "creatorAccount.menuPersonalInfo": "Personal Info",
    "creatorAccount.menuSocialMedia": "Social Media",
    "creatorAccount.menuPassword"   : "Password",

    "accountSettings.editProfile"     : "Ubah Profil",
    "accountSettings.socialMedia"     : "Social Media",
    "accountSettings.changePassword"  : "Ganti Password",

    "accountSettingsSocialMedia.instagram": "INSTAGRAM USERNAME*",
    "accountSettingsSocialMedia.tiktok"   : "TIKTOK USERNAME",
    "accountSettingsSocialMedia.youtube"  : "YOUTUBE CHANNEL",
    "accountSettingsSocialMedia.twitter"  : "TWITTER USERNAME",

    "accountSettingsSocialMedia.modalTitleInstagram"  : "Let's optimize your Instagram account",
    "accountSettingsSocialMedia.modalDescInstagram"  : "To be able to connect your Instagram Account :",
    "accountSettingsSocialMedia.pointOneInstagram"  : "Set to business or creator account",
    "accountSettingsSocialMedia.pointTwoInstagram"  : "Connected to your facebook page",
    "accountSettingsSocialMedia.pointThreeInstagram"  : "Has  at least 100 followers",
    "accountSettingsSocialMedia.modalTitleYoutube"  : "Let's optimize your YouTube account",
    "accountSettingsSocialMedia.modalDescYoutube"  : "To be able to connect your Youtube Account :",
    "accountSettingsSocialMedia.pointOneYoutube"  : "Need to have atleast 5 subscriber",
    "accountSettingsSocialMedia.pointTwoYoutube"  : "Subscriber count are not hidden",
    "accountSettingsSocialMedia.modalTitleTiktok"  : "Let's optimize your Tiktok account",
    "accountSettingsSocialMedia.modalDescTiktok"  : "To be able to connect your Tiktok Account :",
    "accountSettingsSocialMedia.pointOneTiktok"  : "Need to have 5 or more videos",
    "accountSettingsSocialMedia.pointTwoTiktok"  : "Need to have atleast 25 followers",
    "accountSettingsSocialMedia.modalTitleTwitter"  : "Let's optimize your Twitter account",
    "accountSettingsSocialMedia.modalDescTwitter"  : "To be able to connect your Twitter Account :",
    "accountSettingsSocialMedia.pointOneTwitter"  : "Need to have at least 5 followers",

    "accountSettingsSocialMedia.notSure"  : "Not sure how to do it?",
    "accountSettingsSocialMedia.seeHere"  : "See Here",

    "accountSettingsSosmed.title"         : "Hubungkan Akun Media Sosialmu",
    "accountSettingsSosmed.desc"          : "You can connect you your Instagram or TikTok",
    "accountSettingsSosmed.bintanGO"      : "BINTANGO ACCOUNT",
    "accountSettingsSosmed.instagram"     : "Instagram Account",
    "accountSettingsSosmed.youtube"       : "YOUTUBE ACCOUNT",
    "accountSettingsSosmed.tiktok"        : "Tiktok Account",
    "accountSettingsSosmed.twitter"       : "TWITTER ACCOUNT",
    "accountSettingsSosmed.connectSosmed" : "Connect Social Media",

    "accountSettingsSosmed.dontWorry"     : "Jangan takut! Informasi kamu aman dengan kita",
    "accountSettingsSosmed.titleConnect"  : "Media Sosialmu yang terhubung",
    "accountSettingsSosmed.descConnect"   : "Dapatkan Insight untuk semua akun media sosialmu!",

    "accountSettingsSosmed.disconnectTitle"  : "Oh no, apakah kamu ingin melepaskan koneksi dari akun {platform}-mu?:(",
    "accountSettingsSosmed.disconnectDesc"   : "Akun {platform} milikmu tidak akan bisa diakses melalui BintanGO",

    "accountSettingsSosmed.selectedTitle"  : "Are you sure want to change your default social media?",
    "accountSettingsSosmed.selectedDesc1"   : "By changing your default social media, there will be some impact on your bintanGO account : ",
    "accountSettingsSosmed.selectedDesc2"   : "When you apply jobs in BintanGO, we will user your new default social media",
    "accountSettingsSosmed.selectedDesc3"   : "All analytics data will change to your new default social media",
    "accountSettingsSosmed.selectedDesc4"   : "You will change to",
    "accountSettingsSosmed.changeDefaultAccount"   : "CHANGE DEFAULT ACCOUNT",
    "accountSettingsSosmed.cancel"          : "CANCEL",

    "accountSettingsSosmed.username"      : "USERNAME",
    "accountSettingsSosmed.usernameOnce"  : "Username kamu hanya dapat diganti 1 kali",
    "accountSettingsSosmed.usernameChange": "URL Mylink kamu juga akan terganti",
    
    "accountSettingsSocialMedia.titleNotValidIG"  : "We cannot find your IG",
    "accountSettingsSocialMedia.descNotValidIG"  : "It looks like your instagram is not a business account, you will miss some of our features. But don't worry, you can still continue to register as a creator",
    "accountSettingsSocialMedia.tryAgain"  : "TRY AGAIN",
    "accountSettingsSocialMedia.continue"  : "CONTINUE",

    "accountSettingsSosmed.responseUnderReview"  : "We will review your account. It may take a few days",
    "accountSettingsSosmed.responseWeCant"  : "We can't connect to your account.",
    "accountSettingsSosmed.seeHereWhy": "See here why",

    "accountSettingsSosmed.info": "Social Media you select will be use for you applying jobs, get data analytics, and all feature in BintanGO",
    "accountSettingsSosmed.unlink": "Unlink",

    "accountSettingsSosmed.titleCantUnlink": "Right now your social media is being used",
    "accountSettingsSosmed.descCantUnlink": "Sorry you can't change your social media, your social media is currently being used in a campaign",

    "accountSettingsSosmedConnect.firstTitle": "Keamanan Utama",
    "accountSettingsSosmedConnect.firstDesc": "Keselamatan data Kamu adalah yang Utama. Langkah-langkah keamanan diimplementasikan untuk melindungi informasi Kamu.",

    "accountSettingsSosmedConnect.secondTitle": "Data Berdasarkan Persetujuan",
    "accountSettingsSosmedConnect.secondDesc": "Kami hanya mengumpulkan data yang Kamu izinkan. Persetujuan Kamu mengarahkan proses pengumpulan data kami.",

    "accountSettingsSosmedConnect.thirdTitle": "Data Kamu Aman dan Terlindungi",
    "accountSettingsSosmedConnect.thirdDesc": "Data Kamu aman dan terjaga. Kepercayaan Kamu adalah prioritas kami.",

    "accountSettingsChangePassword.note"  : "Kami akan mengirimkan URL untuk reset password kamu. Pastikan email yang kamu input sudah sesuai dengan yang terdaftar untuk akun ini.",
    "accountSettingsChangePassword.email" : "EMAIL",
    "accountSettingsChangePassword.emailAddress" : "E-MAIL ADDRESS",
    "accountSettingsChangePassword.resetPassword" : "Reset Password",
    "accountSettingsChangePassword.sendLink" : "Kirim Link",

    "accountSettingsEditProfile.myProfile": "My Profile",
    "accountSettingsEditProfile.desc"     : "basic info of",
    "accountSettingsEditProfile.personal" : "Personal Information",
    "accountSettingsEditProfile.descPersonal" : "information about you, only you",
    "accountSettingsEditProfile.changepp" : "GANTI GAMBAR PROFIL",
    "accountSettingsEditProfile.fullname" : "NAMA LENGKAP",
    "accountSettingsEditProfile.username" : "USERNAME",
    "accountSettingsEditProfile.provinceCity" : "PROVINSI & KOTA",
    "accountSettingsEditProfile.yourProvince" : "PROVINSI",
    "accountSettingsEditProfile.yourCity" : "KOTA",
    "accountSettingsEditProfile.bio"      : "BIO",
    "accountSettingsEditProfile.gender"   : "JENIS KELAMIN",
    "accountSettingsEditProfile.selectGender" : "Pilih Jenis Kelamin",
    "accountSettingsEditProfile.man"      : "Pria",
    "accountSettingsEditProfile.woman"    : "Wanita",
    "accountSettingsEditProfile.birthday" : "TANGGAL LAHIR",
    "accountSettingsEditProfile.reset"    : "ATUR ULANG",
    "accountSettingsEditProfile.save"     : "Simpan Perubahan",
    "accountSettingsEditProfile.update"   : "UPDATE",
    "accountSettingsEditProfile.savechange": "SAVE CHANGE",
    "accountSettingsEditProfile.connect"  : "CONNECT",
    "accountSettingsEditProfile.disconnect"  : "DISCONNECT",
    "accountSettingsEditProfile.connecting"  : "CONNECTING",
    "accountSettingsEditProfile.savenback": "Save & back",
    "accountSettingsEditProfile.category" : "KATEGORI",
    "accountSettingsEditProfile.mainCategory" : "KATEGORI UTAMA",
    "accountSettingsEditProfile.subCategory" : "SUB KATEGORI (MAKSIMAL 3 KATEGORI)",
    "accountSettingsEditProfile.phone" : "WHATSAPP NUMBER",
    "accountSettingsEditProfile.phoneNew" : "NEW WHATSAPP NUMBER",
    "accountSettingsEditProfile.note" : "Informasi Personal tidak dapat dilihat oleh pengguna. Apabila ingin melakukan perubahan email atau nomor telepon harap menghubungi team customer service melalui whatsapp ke ",
    
    "accountSettingsEditProfile.selectCategory" : "Pilih Kategori",
    "accountSettingsEditProfile.selectSubcategory" : "Pilih Subkategori",
    "accountSettingsEditProfile.selectAll" : "Select All",
    "accountSettingsEditProfile.clearAll" : "CLEAR ALL",

    "accountWhatsapp.titleSent" : "Verifikasi Nomor Hpmu",
    "accountWhatsapp.descSent" : "Kami telah mengirim kode 4 digit ke WhatsAppmu",
    "accountWhatsapp.titleFooter" : "Tidak mendapatkan kode?",
    "accountWhatsapp.descFooter" : "Tunggu {second} detik untuk mengirim ulang kode",
    "accountWhatsapp.titleSuccess" : "Nomor WhatsAppmu telah diverifikasi",
    "accountWhatsapp.descSuccess" : "Yeay! Proses Verifikasi sudah selesai, kamu boleh menutup halaman ini.",
    "accountWhatsapp.resendCode"    : "Kode baru berhasil dikirim ulang!",
    "accountWhatsapp.titleLimit"    : "Kamu sudah memenuhi batas permintaan kode verifikasi",
    "accountWhatsapp.descLimit"     : "Kamu bisa mencoba verifikasi lagi besok",
    "accountWhatsapp.titleChange"   : "Ubah nomor WhatsApp-mu",
    "accountWhatsapp.descChange"    : "Kami akan mengirim kode verifikasi ke nomor WhatsApp-mu yang baru",
    "accountWhatsapp.sameNumber"    : "Tidak bisa menggunakan nomor sebelumnya",

    "accountSettingsEditProfile.ModalCatTitle"  : "What describe you best",
    "accountSettingsEditProfile.ModalCatDesc"   : "Tell us what you like, maybe we got something for you",

    "creator.noPageTitle"              : "Currently this feature only available on Apps",
    "creator.noPageDesc"               : "Download BintanGO application now to access this features",

    "referralNotYet.title"              : "Point tambahan, tanpa banting tulang",
    "referralNotYet.desc"               : "Berikan dirimu dan temanmu reward dengan menggunakan kode referral!",
    "referral.anyQuestion"              : "Punya pertanyaan tentang referral? ",
    "referral.contactUs"                : "Hubungi kami!",

    "referralHave.title"                : "Kode referral yang telah diaktifkan",
    "referralHave.desc"                 : "Nikmati benefit-nya, bagikani reward-nya bersama temanmu!",

    "referralSuccess.title"            : "Kamu berhasil menggunakan kode {referral}!",
    "referralSuccess.desc"             : "Yeay! Kamu telah mendapatkan {rewards} dengan menggunakan kode {referral}",
    "referralSuccessNoRewards.desc"    : "Thank you for using this refferal code",
    "referralChange.desc"              : "Yah, kamu ngga suka sama kode referral yang kamu pakai? 😢 Hubungi kami untuk menghapus atau mengubah kode referral aktifmu",

    "referralError.wrongCode"          : "Oops kamu memasukkan kode yang salah. Silahkan ketik ulang kodenya",

    "rating.submitReview"              : "Submit Review",
    "rating.submitting"                 : "Submitting...",
    "rating.backHome"                   : "Back to homepage",
};

export default idCreatorProfile;
