/* Languange ID */

var idNavBar = {
   
    //Account
    "navBar.account.myAccount"          : "Akun Saya",
    "navBar.account.celebAccount"       : "Akun Seleb",
    "navBar.account.regis"              : "Daftar",
    "navBar.account.halo"               : "Halo, ",
    "navBar.account.changeToCeleb"      : "Pindah ke Akun Seleb",
    "navBar.account.changeToFans"       : "Pindah ke Akun Fans",

    //Misc
    "navBar.account.notif"              : "Notifikasi",
    "navBar.account.vcschedule"         : "Jadwal Video Call",
    "navBar.account.invoiceCreation"    : "Pembuatan Invoice",
    "navBar.account.registTitle"        : "Daftar sebagai fans",
    "navBar.account.registDesc"         : "untuk mendapatkan fitur lebih banyak dari seleb idola kamu",

    //Notif
    "navBar.notif.title"                : "Belum ada notifikasi nih",
    "navBar.notif.desc"                 : "Kami akan update info-info terbaru dari BintanGO Silakan cek notifikasi kamu secara berkala",
    "navBar.notif.showAll"              : "Lihat Semua Notifikasi",
    "navBar.notif.more"                 : "Tampilkan lebih banyak",

    //Video Call
    "navBar.vc.title"                   : "Jadwal Video Call",
    "navBar.creatorAccount"             : "CREATOR ACCOUNT"
    
  };
  
export default idNavBar;