/* Languange EN */

var enLogin = {  
    /* Login */
    "login.btn"                 : "LOGIN",
    "login.loginDesc"           : "Are you a BintanGO creator? ",
    "login.loginLink"           : "Sign in here",
    "login.join"                : "JOIN NOW",
    "login.varifyFooter"        : "Crafted with ♥ by BintanGO • Copyright 2022",

    "login.form.title"          : "Welcome to BintanGO 🎉",
    "login.form.desc"           : "a place where everybody can be a creator. one step closer with your idol and even closer to be the next idol",
    "login.form.fbRegis"        : "SIGN UP WITH FACEBOOK",
    "login.form.googleRegis"    : "SIGN UP WITH GOOGLE",
    "login.form.appleRegis"     : "SIGN UP WITH APPLE",
    "login.form.emailRegis"     : "SIGN UP WITH EMAIL",
    "login.form.fbLogin"        : "LOG IN WITH FACEBOOK",
    "login.form.googleLogin"    : "LOG IN WITH GOOGLE",
    "login.form.appleLogin"     : "LOG IN WITH APPLE",
    "login.form.emailLogin"     : "LOG IN WITH EMAIL",

    // Login With Email 
    "login.form.emailTitle"     : "Welcome back, creatorGO! 🔥",
    "login.form.emailDesc"      : "ready to grow and beyond? let’s sign in to unlimited possibilities ahead into Creator Space",
    "login.form.password"       : "PASSWORD",
    "login.form.passwordPh"     : "Type your password",
    "login.form.signIn"         : "SIGN IN",    

    // Reset Password
    "login.resetPass.title"         : "Reset your Password 🔐",
    "login.resetPass.desc"          : "enter your registered e-mail address. we will send you the link to your e-mail so you can reset your password",
    "login.resetPass.email"         : "E-MAIL ADDRESS",
    "login.resetPass.emailPh"       : "Enter your e-mail here",
    "login.resetPass.sendBtn"       : "SEND RESET LINK",
    "login.resetPass.resetOptDesc"  : "Forgot your password? Don’t worry! ",
    "login.resetPass.resetOptLink"  : "Reset it here",
    
    // Reset Password Verify
    "login.verifyResetPass.title"   : "Reset link sent 📬",
    "login.verifyResetPass.desc1"   : "Reset link for your password has been sent to email ",
    "login.verifyResetPass.desc2"   : ", Please check your e-mail to continue the process.",

    // Reset Password Success
    "login.successResetPass.title"  : "Password successfully changed",
    "login.successResetPass.desc"   : "You can now start exploring BintanGO. But first you need to login",

    // Register Success
    "login.successRegister.title"   : "Email verified successfully",
    "login.successRegister.desc"    : "You can now start exploring BintanGO. But first you need to login",
    
    // Register 
    "login.register.pass2"          : "RE-TYPE YOUR PASSWORD",
    "login.register.phone"          : "WHATSAPP NUMBER",
    "login.register.signUp"         : "SIGN UP",
    "login.register.signUpTS1"      : "By submitting this information you agree to the ",
    "login.register.signUpTS2"      : " and ",
    "login.register.signUpTS3"      : " as a BintanGO creator",
    "login.register.terms"          : "Terms of Use",
    "login.register.privacy"        : "Privacy Policy",
    "login.register.regisOptDesc"   : "Do you want to be a creator? ",
    "login.register.regisOptLink"   : "Sign up here",
    "login.register.verifyTitle"    : "Verification Code Sent 📬",
    "login.register.verifyDesc1"    : "the verification link has been sent to email ",
    "login.register.verifyDesc2"    : ", please verify to be able to continue the registration process",

    // Register Celeb
    "login.registerCeleb.titleStep1"    : "Tell Us About You",
    "login.registerCeleb.descStep1"     : "Help us help you! complete your basic information so we can better understand your content creator needs",
    "login.registerCeleb.titleStep2"    : "Connect Your Social Media",
    "login.registerCeleb.descStep2"     : "so we can integrate a lot of tools and recommendation for your creator account",
    "login.registerCeleb.step1"         : "basic info",
    "login.registerCeleb.step2"         : "social media",
    "login.registerCeleb.fullName"      : "FULL NAME",
    "login.registerCeleb.fullNamePh"    : "John Doe",
    "login.registerCeleb.provinceCity"  : "PROVINCE & CITY *",
    "login.registerCeleb.provincePh"    : "Select Province",
    "login.registerCeleb.cityPh"        : "Select City",
    "login.registerCeleb.dob"           : "DATE OF BIRTH",
    "login.registerCeleb.dobPh"         : "DD/MM/YYYYY",
    "login.registerCeleb.gender"        : "GENDER",
    "login.registerCeleb.genderPh"      : "Choose any",
    "login.registerCeleb.genderM"       : "Male",
    "login.registerCeleb.genderF"       : "Female",
    "login.registerCeleb.username"      : "BINTANGO ACCOUNT (VISIBLE URL FOR BRANDS AND CLIENTS)",
    "login.registerCeleb.usernamePh"    : "username",
    "login.registerCeleb.igAcc"         : "INSTAGRAM ACCOUNT(REQUIRED)",
    "login.registerCeleb.ytAcc"         : "YOUTUBE ACCOUNT",
    "login.registerCeleb.tiktokAcc"     : "TIKTOK ACCOUNT",
    "login.registerCeleb.twitterAcc"    : "TWITTER ACCOUNT",

    "login.registerCeleb.publicProfile" : "Your Public Profile",
    "login.registerCeleb.personalInfo"  : "Your Personal Information",
    "login.registerCeleb.igConnect"     : "Add Your Instagram Account",
    "login.registerCeleb.otherSosmed"   : "How are you performing on social media?",
    "login.registerCeleb.oSosmedDesc"   : "Connect your accounts, gather insights, analytics, as well as earn BintanGO points!",

    "login.registerCeleb.dcTitle1"       : "Want to disconnect your ",
    "login.registerCeleb.dcTitle2"       : " account?",
    "login.registerCeleb.dcDesc1"        : "Your ",
    "login.registerCeleb.dcDesc2"        : " account @",
    "login.registerCeleb.dcDesc3"        : " will get disconnected with BintanGO",
    

    // Register Celeb Success
    "login.registerCeleb.successTitle"      : "Yeay, now you are a Verified Creator!",
    "login.registerCeleb.successDesc"       : "you can enjoy all the features in BintanGO",
    "login.registerCeleb.backHome"          : "BACK TO HOMEPAGE",
    "login.registerCeleb.step1"             : "basic info",
    "login.registerCeleb.step2"             : "social media",

    //SideBar Login
    "login.sideBar.title"         : "Let’s Grow and Beyond 🚀",
    "login.sideBar.desc"          : "Meet the New Way Creators Run their Business, Engage with Brands and Grow their Fans",

    // Register Skip
    "login.registerCeleb.skipTitle"     : "Do you want to skip this progress?",
    "login.registerCeleb.skipDesc"      : "Your data will be saved from the latest change you have made",
    "login.registerCeleb.skipSaveBtn"   : "SAVE & SKIP",

    "login.registerCeleb.successSkipTitle"      : "Welcome to BintanGO",
    "login.registerCeleb.successSkipDesc"       : "Become an awesome creator, grow beyond with BintanGO",

    // Success Login
    "login.successLogin.title"     : "Do you have a referral code?",
    "login.successLogin.desc"      : "Enter your code to jumpstart your BintanGO journey!",
    "login.successLogin.have"      : "Yes, I Have",
    "login.successLogin.later"     : "Maybe later",

    // Refferal
    "login.referal.title"               : "Have a referral code?",
    "login.referal.desc"                : "let’s input code and get lorem ipsum the promise ",
    "login.referal.popupRegisTitle"     : "Registration Confirmation ",
    "login.referal.popupRegisDesc1"     : "you will register using ",
    "login.referal.popupRegisDesc2"     : " referral code",
    
    "login.referal.titleSubmit"     : "Enter your referral code",
    "login.referal.descSubmit"      : "Points and rewards to get you started!",
    "login.referal.submitPh"        : "Enter your referral code here",

    // Invited Register 
    "login.invRegist.Ltitle"        : "Selamat kamu telah diundang untuk menjadi kreator BintanGO",
    "login.invRegist.Ldesc"         : "Kamu telah diundang untuk mengikuti salah satuu campaign di BintanGO. Segera lanjut untuk mengisi data yang diperlukan",
    "login.invRegist.Lbtn"          : "Lanjut",
    "login.invRegist.formTitle"     : "Registrasi Kreator",
    "login.invRegist.formDesc"      : "Yohoo Kamu diundang menjadi kreator BintanGO, yuk isi informasi dibawah ini",
    "login.invRegist.formFillTitle" : "Informasi Registrasi",
    "login.invRegist.waDesc"        : "Nomor ini akan digunakan untuk tim kami untuk meng-kontak Kamu",
    "login.invRegist.waHelp"        : "Kenapa harus verifikasi?",
    "login.invRegist.socMedTitle"   : "Hubungkan Social Media Kamu",
    "login.invRegist.socMedDesc"    : "Hubungkan sosial media yang akan kamu gunakan untuk membuat konten atau bekerja",
    "login.invRegist.save"          : "SIMPAN INFORMASI",

    "login.invRegist.formTitleBank" : "Informasi Bank",
    "login.invRegist.formDescBank"  : "1 langkah lagi supaya Kamu lebih mudah menerima pembayaran dari kami",
    "login.invRegist.bankName"      : "NAMA BANK",
    "login.invRegist.bankNamePh"    : "Pilih bank",
    "login.invRegist.bankOwner"     : "NAMA PEMILIK BANK",
    "login.invRegist.bankOwnerPh"   : "Contoh : Jhon Doe",
    "login.invRegist.bankNumber"    : "NOMOR REKENING",
    "login.invRegist.term"          : "I agree BintanGO ",
    "login.invRegist.termLink"      : "Terms and Condition",
    "login.invRegist.bankNumberPh"  : "Masukkan nomor rekening bank Kamu",
    "login.invRegist.bankSave"      : "SIMPAN",
    "login.bankModal.title"         : "Pilih Bank",
    "login.bankModal.searchPh"      : "Cari bank",


    // Connect 
    "login.cmp.title1"              : "Hi, ",
    "login.cmp.title2"              : "You joined a campaign, let's connect your social media",
    "login.cmp.branded"             : "Branded Content",
    "login.cmp.socMedTitle"         : "Hubungkan Social Media Kamu",
    "login.cmp.socMedDesc"          : "Yuk hubungkan social media kamu untuk campaign yang akan kamu kerjakan",
    "login.cmp.saveInfo"            : "Simpan informasi",
    "login.cmp.successTitle"        : "Yohoo, you have successfully connected social media",
    "login.cmp.successDesc"         : "Thank you for connecting your social media",
};

export default enLogin;
