/* Languange ID */

var enCreatorProfile = {  
    "index.topVerifiedCreator"          : "Get more opportunities and benefits by becoming a verified creator",
    "index.buttonTopVerifiedCreator"    : "VERIFY ME!",
    "index.modalTitleVerifiedCreator"   : "Be a verified creator and go beyond! 🚀",
    "index.modalDescVerifiedCreator"    : "You will never be ready, just start now💪🏻. One more step to get your creator freedom with BintanGO",
    "index.modalbuttonVerifiedCreator"    : "VERIFY ME",
    "index.modalbuttonLaterVerifiedCreator"    : "NO, MAYBE LATER",

    "sidebar.home"                  : "Home",
    "sidebar.dashboard"             : "Dashboard",
    "sidebar.brandDeals"            : "Jobs",
    "sidebar.monetization"          : "Monetization",
    "sidebar.financing"             : "Financing",
    "sidebar.analytics"             : "Analytics",
    "sidebar.mission"               : "Mission",
    "sidebar.invoice"               : "Invoice",
    "sidebar.myLink"                : "My Link",
    "sidebar.forum"                 : "Forum",
    "sidebar.fanWorld"              : "Fan World",
    "sidebar.profile"               : "Profile",
    "sidebar.personalInformation"   : "Personal Information",
    "sidebar.creatorProfile"        : "Creator Profile",
    "sidebar.accountSetting"        : "Account Setting",
    "sidebar.creatorSetting"        : "Creator Setting",
    "sidebar.bankAccount"           : "Bank Account",
    "sidebar.sosmed"                : "Social Media",
    "sidebar.accountSecurity"       : "Account Security",
    "sidebar.referral"              : "Referral",
    "sidebar.earning"               : "Earning",
    "sidebar.balance"               : "Balance",
    "sidebar.celebritySetting"      : "Celebrity Setting",
    "sidebar.myTask"                : "My Task",
    "sidebar.services"              : "Services",
    "sidebar.vacationMode"          : "Vacation Mode",
    "sidebar.backToHome"            : "Back to home",
    "sidebar.minimize"              : "Minimize",

    "creatorHome.titleWelcome"      : "Welcome Aboard to CreatorSpace",
    "creatorHome.descWelcome"       : "A place where everybody can be a creator, all wrapped up in one space\nLet’s grow beyond!",
    "creatorHome.titleBrandDeals"   : "Brand New Brand Deals For You!",
    "creatorHome.descBrandDeals"    : "Register now before quota runs out!",
    "creatorHome.titleComment"      : "The quickest and easiest deals!",
    "creatorHome.descComment"       : "Earn in 5 minutes, Comment + share, done!",
    "creatorHome.titleJob"          : "Opportunities just fo you!",
    "creatorHome.descJob"           : "Specially crafted suggestions based on your profile!",
    "creatorHome.balance"           : "Balance",
    "creatorHome.points"            : "Points",
    "creatorHome.redeem"            : "REDEEM",
    "creatorHome.infoDesc"          : "Explore more tools and features on BintanGO app\n Download now to get the best experience of BintanGO",
    "creatorHome.menuTitleMyLink"   : "Build your own page",
    "creatorHome.menuDescMyLink"    : "build your portfolio, links and anything on BintanGO MyLink",
    "creatorHome.menuTitleMission"  : "Claim your mission",
    "creatorHome.menuDescMission"   : "Complete missions, collect points and buy anything with points",
    "creatorHome.menuTitleInvoice"  : "Send your first invoice",
    "creatorHome.menuDescInvoice"   : "Easily create and send your first invoice with BintanGO",
    "creatorHome.menuTitleFinancing": "Apply financing now",
    "creatorHome.menuDescFinancing" : "Say goodbye to waiting! Paid faster with us",
    "creatorHome.exclusiveForVerified" : "EXCLUSIVE FOR VERIFIED CREATOR",

    "creatorHome.downloadBintagoTitle" : "Explore BintanGO through our Apps",
    "creatorHome.downloadBintagoDesc" : "More Personalized Job Opportunities, Integrated Analytics, creator forum and more! Download BintanGO today!",
    "creatorHome.downloadBintagoGetTheApp" : "Download BintanGO",

    "downloadApps.title"                : "Access this feature and more through our app",
    "downloadApps.desc"                 : "More Personalized Job Opportunities, Integrated Analytics, creator forum, and more! A better and faster way to explore CreatorSpace, start your BintanGO journey today!",

    "creatorHome.onBoardingOneTitle"    : "Basic Creator Information",
    "creatorHome.onBoardingOneDesc"     : "Tell us about you, so we can understand better",
    "creatorHome.onBoardingTwoTitle"    : "Connect your social media",
    "creatorHome.onBoardingTwoDesc"     : "Connect min. 2 accounts and gather insight",
    "creatorHome.onBoardingThreeTitle"  : "Location",
    "creatorHome.onBoardingThreeDesc"   : "We'll find opportunities near you",
    "creatorHome.onBoardingFourTitle"   : "Add your category",
    "creatorHome.onBoardingFourDesc"    : "Tell us who you are, maybe we got something",

    "creatorDashboard.titleWelcome"      : "Creator Dashboard",
    "creatorDashboard.descWelcome"       : "All your insights and needs, all wrapped up in one dashboard",

    "creatorBrandDeals.titleWelcome"     : "Explore Our Jobs",
    "creatorBrandDeals.descWelcome"      : "many kind of jobs you can check in here",
    "creatorBrandDeals.stillCounting"    : "...and still counting",

    "creatorListJobs.titleWelcome"      : "Lorem Ipsum Init Condinental",
    "creatorListJobs.descWelcome"       : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor risus purus. ",

    "creatorHistoryJobs.titleWelcome"      : "Jobs History",
    "creatorHistoryJobs.descWelcome"       : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor risus purus. ",

    "creatorMonetization.titleWelcome"     : "💸 Monetization Tools for Creator",
    "creatorMonetization.descWelcome"      : "we give you a secret how to earn easy money. just a click and let the money work for you.",

    "postMonetization.successTitle" : "Yeay, success post it to your Instagram",
    "postMonetization.successDesc"  : "Go check to your post on your instagram apps or you can check other deals & earn more",
    "postMonetization.continue"     : "CONTINUE",

    "postMonetization.failedTitle"  : "Sorry, we can't post to your instagram",
    "postMonetization.failedDesc"   : "Somehow we can't post it to your instagram, let's try again",
    "postMonetization.tryAgain"     : "TRY AGAIN",
    "postMonetization.later"        : "LATER",
    "postMonetization.contactUs"    : "CONTACT US",

    "postMonetization.jobTitle"     : "Well done! You have done all jobs",
    "postMonetization.jobDesc"   : "Check again periodically to get the most updated jobs in buzz hunt!",
    "postMonetization.backToHome"   : "BACK TO HOME",
    "postMonetization.explore"      : "EXPLORE BRAND DEALS",

    "creatorAccount.category"       : "category",
    "creatorAccount.subCategory"    : "sub category",
    "creatorAccount.seeAll"         : "SEE ALL",
    "creatorAccount.menuCreatorInfo": "Creator Info",
    "creatorAccount.menuPersonalInfo": "Personal Info",
    "creatorAccount.menuSocialMedia": "Social Media",
    "creatorAccount.menuPassword"   : "Password",

    "accountSettings.editProfile"     : "Edit Profile",
    "accountSettings.socialMedia"     : "Social Media",
    "accountSettings.changePassword"  : "Change Password",

    "accountSettingsSocialMedia.instagram": "INSTAGRAM USERNAME*",
    "accountSettingsSocialMedia.tiktok"   : "TIKTOK USERNAME",
    "accountSettingsSocialMedia.youtube"  : "YOUTUBE CHANNEL",
    "accountSettingsSocialMedia.twitter"  : "TWITTER USERNAME",

    "accountSettingsSocialMedia.modalTitleInstagram"  : "Let's optimize your Instagram account",
    "accountSettingsSocialMedia.modalDescInstagram"  : "To be able to connect your Instagram Account :",
    "accountSettingsSocialMedia.pointOneInstagram"  : "Set to business or creator account",
    "accountSettingsSocialMedia.pointTwoInstagram"  : "Connected to your facebook page",
    "accountSettingsSocialMedia.pointThreeInstagram"  : "Has  at least 100 followers",
    "accountSettingsSocialMedia.modalTitleYoutube"  : "Let's optimize your YouTube account",
    "accountSettingsSocialMedia.modalDescYoutube"  : "To be able to connect your Youtube Account :",
    "accountSettingsSocialMedia.pointOneYoutube"  : "Need to have atleast 5 subscriber",
    "accountSettingsSocialMedia.pointTwoYoutube"  : "Subscriber count are not hidden",
    "accountSettingsSocialMedia.modalTitleTiktok"  : "Let's optimize your Tiktok account",
    "accountSettingsSocialMedia.modalDescTiktok"  : "To be able to connect your Tiktok Account :",
    "accountSettingsSocialMedia.pointOneTiktok"  : "Need to have 5 or more videos",
    "accountSettingsSocialMedia.pointTwoTiktok"  : "Need to have atleast 25 followers",
    "accountSettingsSocialMedia.modalTitleTwitter"  : "Let's optimize your Twitter account",
    "accountSettingsSocialMedia.modalDescTwitter"  : "To be able to connect your Twitter Account :",
    "accountSettingsSocialMedia.pointOneTwitter"  : "Need to have at least 5 followers",

    "accountSettingsSocialMedia.notSure"  : "Not sure how to do it?",
    "accountSettingsSocialMedia.seeHere"  : "See Here",

    "accountSettingsSosmed.title"         : "Connect your sosial media",
    "accountSettingsSosmed.desc"          : "You can connect you your Instagram or TikTok",
    "accountSettingsSosmed.bintanGO"      : "BINTANGO ACCOUNT",
    "accountSettingsSosmed.instagram"     : "Instagram Account",
    "accountSettingsSosmed.youtube"       : "YOUTUBE ACCOUNT",
    "accountSettingsSosmed.tiktok"        : "Tiktok Account",
    "accountSettingsSosmed.twitter"       : "TWITTER ACCOUNT",
    "accountSettingsSosmed.connectSosmed" : "Connect Social Media",

    "accountSettingsSosmed.dontWorry"     : "Don't worry! Your information is safe with us",
    "accountSettingsSosmed.titleConnect"  : "Connected Social Media",
    "accountSettingsSosmed.descConnect"   : "All your social media insight in one place!",

    "accountSettingsSosmed.disconnectTitle"  : "Oh no, do you want to disconnect your {platform} account?:(",
    "accountSettingsSosmed.disconnectDesc"   : "Your {platform} account {username} will no longer be accessible through BintanGO",

    "accountSettingsSosmed.selectedTitle"  : "Are you sure want to change your default social media?",
    "accountSettingsSosmed.selectedDesc1"   : "By changing your default social media, there will be some impact on your bintanGO account : ",
    "accountSettingsSosmed.selectedDesc2"   : "When you apply jobs in BintanGO, we will user your new default social media",
    "accountSettingsSosmed.selectedDesc3"   : "All analytics data will change to your new default social media",
    "accountSettingsSosmed.selectedDesc4"   : "You will change to",
    "accountSettingsSosmed.changeDefaultAccount"   : "CHANGE DEFAULT ACCOUNT",
    "accountSettingsSosmed.cancel"          : "CANCEL",

    "accountSettingsSosmed.username"      : "USERNAME",
    "accountSettingsSosmed.usernameOnce"  : "you can only change your username once",
    "accountSettingsSosmed.usernameChange": "your MyLink URL will be changed",

    "accountSettingsSocialMedia.titleNotValidIG"  : "We cannot find your IG",
    "accountSettingsSocialMedia.descNotValidIG"  : "It looks like your instagram is not a business account, you will miss some of our features. But don't worry, you can still continue to register as a creator",
    "accountSettingsSocialMedia.tryAgain"  : "TRY AGAIN",
    "accountSettingsSocialMedia.continue"  : "CONTINUE",

    "accountSettingsSosmed.responseUnderReview"  : "We will review your account. It may take a few days",
    "accountSettingsSosmed.responseWeCant"  : "We can't connect to your account.",
    "accountSettingsSosmed.seeHereWhy": "See here why",

    "accountSettingsSosmed.info": "Social Media you select will be use for you applying jobs, get data analytics, and all feature in BintanGO",
    "accountSettingsSosmed.unlink": "Unlink",

    "accountSettingsSosmed.titleCantUnlink": "Right now your social media is being used",
    "accountSettingsSosmed.descCantUnlink": "Sorry you can't change your social media, your social media is currently being used in a campaign",

    "accountSettingsSosmedConnect.firstTitle": "Security First",
    "accountSettingsSosmedConnect.firstDesc": "Your safety is paramount. Rigorous security measures are in place to shield your information. Keamanan Utama",

    "accountSettingsSosmedConnect.secondTitle": "Consent-Based Data",
    "accountSettingsSosmedConnect.secondDesc": "We only gather data you green-light. Your approval guides our data collection process. Data Berdasarkan Persetujuan",

    "accountSettingsSosmedConnect.thirdTitle": "Your Data is Secure and Safe",
    "accountSettingsSosmedConnect.thirdDesc": "Your data is safe and protected. Your trust is our priority.",

    "accountSettingsChangePassword.note"  : "We will send you a URL to reset your password. Make sure the email you input matches the one recorded for this account.",
    "accountSettingsChangePassword.email" : "EMAIL",
    "accountSettingsChangePassword.emailAddress" : "E-MAIL ADDRESS",
    "accountSettingsChangePassword.resetPassword" : "Reset Password",
    "accountSettingsChangePassword.sendLink" : "SEND RESET LINK",

    "accountSettingsEditProfile.myProfile": "Basic Creator Information",
    "accountSettingsEditProfile.desc"     : "basic info of",
    "accountSettingsEditProfile.personal" : "Personal Information",
    "accountSettingsEditProfile.descPersonal" : "information about you, only you",
    "accountSettingsEditProfile.changepp" : "CHANGE PROFILE PICTURE",
    "accountSettingsEditProfile.fullname" : "FULLNAME",
    "accountSettingsEditProfile.username" : "USERNAME",
    "accountSettingsEditProfile.provinceCity" : "PROVINCE & CITY",
    "accountSettingsEditProfile.yourProvince" : "YOUR PROVINCE",
    "accountSettingsEditProfile.yourCity" : "YOUR CITY",
    "accountSettingsEditProfile.bio"      : "BIO",
    "accountSettingsEditProfile.gender"   : "GENDER",
    "accountSettingsEditProfile.selectGender" : "Select Gender",
    "accountSettingsEditProfile.man"      : "Male",
    "accountSettingsEditProfile.woman"    : "Female",
    "accountSettingsEditProfile.birthday" : "DOB",
    "accountSettingsEditProfile.reset"    : "Reset",
    "accountSettingsEditProfile.save"     : "Save Changes",
    "accountSettingsEditProfile.update"   : "UPDATE",
    "accountSettingsEditProfile.savechange": "SAVE CHANGE",
    "accountSettingsEditProfile.connect"  : "CONNECT",
    "accountSettingsEditProfile.disconnect"  : "DISCONNECT",
    "accountSettingsEditProfile.connecting"  : "CONNECTING",
    "accountSettingsEditProfile.savenback": "Save & back",
    "accountSettingsEditProfile.category" : "CATEGORY",
    "accountSettingsEditProfile.mainCategory" : "MAIN CATEGORY",
    "accountSettingsEditProfile.subCategory" : "SUB CATEGORY (MAX 3 CATEGORIES)",
    "accountSettingsEditProfile.phone" : "WHATSAPP NUMBER",
    "accountSettingsEditProfile.phoneNew" : "NEW WHATSAPP NUMBER",
    "accountSettingsEditProfile.note" : "Personal information cannot be seen by other user. If you want to change your email or phone number, please contact the customer service team via whatsapp to ",

    "accountSettingsEditProfile.selectCategory" : "Select Category",
    "accountSettingsEditProfile.selectSubcategory" : "Select Subcategory",
    "accountSettingsEditProfile.selectAll" : "Select All",
    "accountSettingsEditProfile.clearAll" : "CLEAR ALL",

    "accountWhatsapp.titleSent"     : "Verify Your Number",
    "accountWhatsapp.descSent"      : "We already sent a 4 digit verification code to your WhatsApp",
    "accountWhatsapp.titleFooter"   : "Didn’t receive the code?",
    "accountWhatsapp.descFooter"    : "Wait {second} seconds to resend the code",
    "accountWhatsapp.titleSuccess"  : "WhatsApp Number Successfully Verified",
    "accountWhatsapp.descSuccess"   : "Yeay! Verification Success, you may now close this window",
    "accountWhatsapp.resendCode"    : "New Code successfully resent",
    "accountWhatsapp.titleLimit"    : "You have reached the limit for today’s verification code request",
    "accountWhatsapp.descLimit"     : "You can try to verify your number again tomorrow",
    "accountWhatsapp.titleChange"   : "Change your WhatsApp number",
    "accountWhatsapp.descChange"    : "We will send a verification code to your new WhatsApp number",
    "accountWhatsapp.sameNumber"    : "Cannot be the same as previous number",

    "accountSettingsEditProfile.ModalCatTitle"  : "What describe you best",
    "accountSettingsEditProfile.ModalCatDesc"   : "Tell us what you like, maybe we got something for you",

    "creator.noPageTitle"              : "Currently this feature only available on Apps",
    "creator.noPageDesc"               : "Download BintanGO application now to access this features",

    "referralNotYet.title"              : "Extra points with no extra effort",
    "referralNotYet.desc"               : "Enter your friend’s referral code and earn special rewards together!",
    "referral.anyQuestion"              : "Feel free to ask us about referrals, ",
    "referral.contactUs"                : "contact us!",

    "referralHave.title"                : "Active referral code",
    "referralHave.desc"                 : "Enjoy the rewards and keep sharing the love💗",

    "referralSuccess.title"            : "You have successfully registered using the {referral} referral code",
    "referralSuccess.desc"             : "Woohoo! You earned {rewards} by using the {referral} referral code",
    "referralSuccessNoRewards.desc"    : "Thank you for using this refferal code",
    "referralChange.desc"              : "Oh no, is there something wrong with your referral code?😢 Contact us to change or delete your active referral code.",

    "referralError.wrongCode"          : "Whoops I’m afraid that’s the wrong code. Please type in the correct code",

    "rating.submitReview"              : "Submit Review",
    "rating.submitting"                 : "Submitting...",
    "rating.backHome"                   : "Back to homepage",
};

export default enCreatorProfile;
