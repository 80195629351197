import appLocaleData from 'react-intl/locale-data/es';
import idID from '../locales/idID';
import idFooter from '../locales/element/idFooter';
import idNavBar from '../locales/element/idNavBar';
import idLogin from '../locales/pages/idLogin';
import idCreatorProfile from '../locales/pages/idCreatorProfile';
import idMonetize from '../locales/pages/idMonetize';
import idBrandDeals from '../locales/pages/idBrandDeals';

const IdLang = {
    messages: {
        ...idID,
        ...idFooter,
        ...idNavBar,
        ...idLogin,
        ...idCreatorProfile,
        ...idMonetize,
        ...idBrandDeals,
    },
    locale: 'en-ID',
    data: appLocaleData
};
export default IdLang;