/* Languange ID */

var idLogin = {  
    /* Login */
    "login.btn"                 : "MASUK",
    "login.loginDesc"           : "Apakah kamu kreator BintanGO ? ",
    "login.loginLink"           : "Login disini",
    "login.join"                : "JOIN NOW",
    "login.varifyFooter"        : "Crafted with ♥ by BintanGO • Copyright 2022",
 
    "login.form.title"          : "Selamat datang di BintanGO 🎉",
    "login.form.desc"           : "Wadah dimana semua orang bisa menjadi Creator, satu langkah lagi untuk lebih dekat dengan idolamu dan mungkin kamu menjadi idola selanjutnya",
    "login.form.fbRegis"        : "DAFTAR DENGAN FACEBOOK",
    "login.form.googleRegis"    : "DAFTAR DENGAN  GOOGLE",
    "login.form.appleRegis"     : "DAFTAR DENGAN APPLE",
    "login.form.emailRegis"     : "DAFTAR DENGAN EMAIL",
    "login.form.fbLogin"        : "MASUK DENGAN FACEBOOK",
    "login.form.googleLogin"    : "MASUK DENGAN GOOGLE",
    "login.form.appleLogin"     : "MASUK DENGAN APPLE",
    "login.form.emailLogin"     : "MASUK DENGAN EMAIL",

    // Login With Email 
    "login.form.emailTitle"     : "Selamat datang, CreatorGO! 🎉",
    "login.form.emailDesc"      : "Sudah siap untuk grow and beyond? Yuk, sign in ke CreatorSpace untuk mendapatkan peluang yang tak terbatas!",
    "login.form.password"       : "PASSWORD",
    "login.form.passwordPh"     : "Masukan passwordmu",
    "login.form.signIn"         : "MASUK",    

    // Reset Password
    "login.resetPass.title"         : "Atur Ulang Password mu 🔐",
    "login.resetPass.desc"          : "Masukkan email kamu yang sudah teregistrasi, kami akan mengirimkan link melalui email sehingga kamu bisa reset password.",
    "login.resetPass.email"         : "ALAMAT E-MAIL",
    "login.resetPass.emailPh"       : "Masukan email kamu disini",
    "login.resetPass.sendBtn"       : "KIRIM RESET LINK",
    "login.resetPass.resetOptDesc"  : "Lupa passwordmu? Jangan khawatir! ",
    "login.resetPass.resetOptLink"  : "Reset disini",
     
    // Reset Password Verify
    "login.verifyResetPass.title"   : "Link reset terkirim 📬",
    "login.verifyResetPass.desc1"   : "Link untuk reset password sudah dikirim ke email ",
    "login.verifyResetPass.desc2"   : ", Mohon cek email kamu untuk melanjukan ke proses selanjutnya",

    // Reset Password Success
    "login.successResetPass.title"  : "Password berhasil terganti",
    "login.successResetPass.desc"   : "Kamu sudah bisa menjelajahi BintanGO, tapi kamu perlu untuk login terlebih dahulu",

    // Register Success
    "login.successRegister.title"   : "Email berhasil diverifikasi",
    "login.successRegister.desc"    : "Kamu sekarang dapat mulai menjelajahi BintanGO. Tapi pertama-tama Kamu harus login",
    
    // Register 
    "login.register.pass2"          : "ULANGI PASSWORD",
    "login.register.phone"          : "NOMOR WHATSAPP",
    "login.register.signUp"         : "SIGN UP",
    "login.register.signUpTS1"      : "Dengan memasukan informasi ini kamu setuju dengan ",
    "login.register.signUpTS2"      : " dan ",
    "login.register.signUpTS3"      : " sebagai kreator BintanGO",
    "login.register.terms"          : "Syarat Penggunaan",
    "login.register.privacy"        : "Kebijakan pribadi",
    "login.register.regisOptDesc"   : "Kamu ingin menjadi creator? ",
    "login.register.regisOptLink"   : "Daftar disini",
    "login.register.verifyTitle"    : "Kode Verifikasi dikirim 📬",
    "login.register.verifyDesc1"    : "Link verifikasi sudah dikirim melalui email ",
    "login.register.verifyDesc2"    : ", Mohon verifikasi terlebih dahulu untuk melanjutkan ke proses registrasi selanjutnya",

    // Register Celeb
    "login.registerCeleb.titleStep1"    : "Jadilah verified Creator untuk akses ke semua fitur ➡️",
    "login.registerCeleb.descStep1"     : "Bantu kami bantu kamu! isi informasi dasar agar kami lebih mengerti kebutuhanmu",
    "login.registerCeleb.titleStep2"    : "Kami perlu informasi sosial media kamu 📱️",
    "login.registerCeleb.descStep2"     : "Untuk kami hubungkan dengan berbagai macam tools dan rekomendasi ke akun Creator kamu",
    "login.registerCeleb.step1"         : "Info basic",
    "login.registerCeleb.step2"         : "social media",
    "login.registerCeleb.fullName"      : "NAMA LENGKAP",
    "login.registerCeleb.fullNamePh"    : "John Doe Alexander",
    "login.registerCeleb.provinceCity"  : "PROVINCE & CITY *",
    "login.registerCeleb.provincePh"    : "Select Province",
    "login.registerCeleb.cityPh"        : "Select City",
    "login.registerCeleb.dob"           : "TANGGAL LAHIR",
    "login.registerCeleb.dobPh"         : "DD/MM/YYYYY",
    "login.registerCeleb.gender"        : "JENIS KELAMIN",
    "login.registerCeleb.genderPh"      : "Pilih salah satu",
    "login.registerCeleb.genderM"       : "Laki Laki",
    "login.registerCeleb.genderF"       : "Perempuan",
    "login.registerCeleb.username"      : "AKUN BINTANGO  (URL YANG BISA DIAKSES BRAND & KLIEN)",
    "login.registerCeleb.usernamePh"    : "username",
    "login.registerCeleb.igAcc"         : "AKUN INSTAGRAM(REQUIRED)",
    "login.registerCeleb.ytAcc"         : "AKUN YOUTUBE",
    "login.registerCeleb.tiktokAcc"     : "AKUN TIKTOK",
    "login.registerCeleb.twitterAcc"    : "AKUN TWITTER",

    "login.registerCeleb.publicProfile" : "Your Public Profile",
    "login.registerCeleb.personalInfo"  : "Your Personal Information",
    "login.registerCeleb.igConnect"     : "Add Your Instagram Account",
    "login.registerCeleb.otherSosmed"   : "Ingin tahu performa media sosialmu?",
    "login.registerCeleb.oSosmedDesc"   : "hubungkan akun medsosmu, dapatkan insights, analytics, dan juga BintanGO points!",

    "login.registerCeleb.dcTitle1"       : "Want to disconnect your ",
    "login.registerCeleb.dcTitle2"       : " account?",
    "login.registerCeleb.dcDesc1"        : "Your ",
    "login.registerCeleb.dcDesc2"        : " account @",
    "login.registerCeleb.dcDesc3"        : " will get disconnected with BintanGO",

    // Register Celeb Success
    "login.registerCeleb.successTitle"     : "Yeay, kamu sekarang Verified Creator!",
    "login.registerCeleb.successDesc"      : "Kamu bisa menikmati semua fiturr di BintanGO",
    "login.registerCeleb.backHome"         : "KEMBALI KE MENU AWAL",
    "login.registerCeleb.step1"            : "Info basic",
    "login.registerCeleb.step2"            : "social media",

    //SideBar Login
    "login.sideBar.title"         : "Let’s Grow and Beyond 🚀",
    "login.sideBar.desc"          : "Meet the New Way Creators Run their Business, Engage with Brands and Grow their Fans",

    // Register Skip
    "login.registerCeleb.skipTitle"     : "Apakah kamu yakin akan melewati proses ini?",
    "login.registerCeleb.skipDesc"      : "Data kamu akan disimpan sesuai perubahan yang kamu lakukan",
    "login.registerCeleb.skipSaveBtn"   : "SAVE & SKIP",

    "login.registerCeleb.successSkipTitle"      : "Welcome to BintanGO",
    "login.registerCeleb.successSkipDesc"       : "Become an awesome creator, grow beyond with BintanGO",

    // Success Login
    "login.successLogin.title"     : "Apakah kamu memiliki kode referral?",
    "login.successLogin.desc"      : "Gunakan kodemu dan awali petualangan BintanGO-mu!",
    "login.successLogin.have"      : "Saya Punya",
    "login.successLogin.later"     : "Mungkin nanti",

    // Refferal
    "login.referal.title"           : "Have a referral code?",
    "login.referal.desc"            : "let’s input code and get lorem ipsum the promise ",
    "login.referal.popupRegisTitle" : "Registration Confirmation ",
    "login.referal.popupRegisDesc1"  : "you will register using ",
    "login.referal.popupRegisDesc2"  : " referral code",

    "login.referal.titleSubmit"     : "Gunakan kode referral-mu!",
    "login.referal.descSubmit"      : "Point dan reward untuk membantu kamu memulai perjalanan BintanGO-mu",
    "login.referal.submitPh"        : "Points and rewards to get you started!",

    // Invited Register 
    "login.invRegist.Ltitle"        : "Selamat kamu telah diundang untuk menjadi kreator BintanGO",
    "login.invRegist.Ldesc"         : "Kamu telah diundang untuk mengikuti salah satuu campaign di BintanGO. Segera lanjut untuk mengisi data yang diperlukan",
    "login.invRegist.Lbtn"          : "Lanjut",
    "login.invRegist.formTitle"     : "Registrasi Kreator",
    "login.invRegist.formDesc"      : "Yohoo Kamu diundang menjadi kreator BintanGO, yuk isi informasi dibawah ini",
    "login.invRegist.formFillTitle" : "Informasi Registrasi",
    "login.invRegist.waDesc"        : "Nomor ini akan digunakan untuk tim kami untuk meng-kontak Kamu",
    "login.invRegist.waHelp"        : "Kenapa harus verifikasi?",
    "login.invRegist.socMedTitle"   : "Hubungkan Social Media Kamu",
    "login.invRegist.socMedDesc"    : "Hubungkan sosial media yang akan kamu gunakan untuk membuat konten atau bekerja",
    "login.invRegist.save"          : "SIMPAN & LANJUT",

    "login.invRegist.formTitleBank" : "Informasi Bank",
    "login.invRegist.formDescBank"  : "1 langkah lagi supaya Kamu lebih mudah menerima pembayaran dari kami",
    "login.invRegist.bankName"      : "NAMA BANK",
    "login.invRegist.bankNamePh"    : "Pilih bank",
    "login.invRegist.bankOwner"     : "NAMA PEMILIK BANK",
    "login.invRegist.bankOwnerPh"   : "Contoh : Jhon Doe",
    "login.invRegist.bankNumber"    : "NOMOR REKENING",
    "login.invRegist.bankNumberPh"  : "Masukkan nomor rekening bank Kamu",
    "login.invRegist.term"          : "I agree BintanGO ",
    "login.invRegist.termLink"      : "Terms and Condition",
    "login.invRegist.bankSave"      : "SIMPAN",
    "login.bankModal.title"         : "Pilih Bank",
    "login.bankModal.searchPh"      : "Cari bank",

    // Connect 
    "login.cmp.title1"              : "Hi, ",
    "login.cmp.title2"              : "Kamu tergabung ke sebuah campaign, yuk hubungkan sosial media Kamu",
    "login.cmp.branded"             : "Branded Content",
    "login.cmp.socMedTitle"         : "Hubungkan Social Media Kamu",
    "login.cmp.socMedDesc"          : "Yuk hubungkan social media kamu untuk campaign yang akan kamu kerjakan",
    "login.cmp.saveInfo"            : "Simpan informasi",
    "login.cmp.successTitle"        : "Yohoo, Kamu berhasil menghubungkan social media",
    "login.cmp.successDesc"         : "Terima kasih telah menghubungkan sosial media kamu",
};

export default idLogin;
