import appLocaleData from 'react-intl/locale-data/en';
import enUS from '../locales/enUS';
import enFooter from '../locales/element/enFooter';
import enNavBar from '../locales/element/enNavBar';
import enLogin from '../locales/pages/enLogin';
import enCreatorProfile from '../locales/pages/enCreatorProfile';
import enMonetize from '../locales/pages/enMonetize';
import enBrandDeals from '../locales/pages/enBrandDeals';

const EnLang = {
    messages: {
        ...enUS,
        ...enFooter,
        ...enNavBar,
        ...enLogin,
        ...enCreatorProfile,
        ...enMonetize,
        ...enBrandDeals,
    },
    locale: 'en-US',
    data: appLocaleData
};
export default EnLang;