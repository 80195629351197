
import React, { Component, Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import AppLocale from './lang';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import AppLayout from './layout/AppLayout';
const Footer = lazy(()=> import("./layout/partial/Footer"));
const LoginLayout = lazy(()=> import("./layout/loginLayout"));

// const Home = lazy(()=> import("./view/public/home"));

const Creator = lazy(()=> import("./view/creator/index"));
const DownloadApps = lazy(()=> import("./view/public/downloadApps"));
const Login = lazy(()=> import("./view/login/loginPage"));
const LoginEmailPage = lazy(()=> import("./view/login/loginEmailPage"));
const Register = lazy(()=> import("./view/login/registerPage"));
const InvitedCreatorRegister = lazy(()=> import("./view/login/invitedCreatorRegister"));
const InvitedCreatorRegisterLanding = lazy(()=> import("./view/login/invitedCreatorRegisterLanding"));
const RegisterEmail = lazy(()=> import("./view/login/registerEmailPage"));
//const RegisterCelebPage = lazy(()=> import("./view/login/registerCelebPage"));
const VerifyRegister = lazy(()=> import("./view/login/verifyRegister"));
const ForgotPassword = lazy(()=> import("./view/login/resetPasswordPage"));
const VerifyForgotPassword = lazy(()=> import("./view/login/verifyResetPasswordPage"));
const SuccessRegisterCeleb = lazy(()=> import("./view/login/successRegisterCelebPage"));
const SuccessSkipRegisterCelebPage = lazy(()=> import("./view/login/successSkipRegisterCelebPage"));
const SuccessLogin = lazy(()=> import("./view/login/successLogin"));
const PopUpLogin = lazy(()=> import("./view/login/popUpLoginPage"));
const PopUpContact = lazy(()=> import("./view/login/popUpContactPage"));
const Notif = lazy(()=> import("./view/login/notifikasi"));
const ConnectCampaign = lazy(()=> import("./view/login/connectCampaign"));
const ConnectCampaignSuccess = lazy(()=> import("./view/login/connectCampaignSuccess"));
// const Register = lazy(()=> import("./view/public/registerPage"));
// const ForgotPassword = lazy(()=> import("./view/public/forgotPasswordPage"));
// const Verification = lazy(()=> import("./view/public/verificationPage"));
const RedirectSSO = lazy(()=> import("./view/public/redirectSSO"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading : "d-none"
    }
  }

  async componentDidMount() {
    try {
      this.setState({loading : "d-block"});
      setTimeout(function() {this.setState({loading : "d-none"})}.bind(this), 800) ;
    }
    catch (error) {
      console.log(error);
    }
    window.scrollTo(0, 0);
  }

  render() {
    const langData = this.props.lang.lang;
    const currentAppLocale = AppLocale[langData];

    //var location = window.location.pathname;
    //var arLoc = location.split("/");

    return (
      <div className="body-of-content">
      
      <div className="h-100">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <React.Fragment>
            <Router >
              <AppLayout>
              <Suspense 
              fallback={<div id="preloader" className={this.state.loading} />}
              >
                <Switch>
                  <Route path='/login' render={(props) => 
                    <LoginLayout>
                        <Login {...props} />
                        {/* <Footer /> */}
                    </LoginLayout> 
                  } />
                  
                  <Route path='/login-email' render={(props) => 
                    <LoginLayout>
                        <LoginEmailPage {...props} />
                        {/* <Footer /> */}
                    </LoginLayout> 
                  } />

                  <Route path='/register' render={(props) => 
                    <LoginLayout>
                        <Register {...props} />
                        {/* <Footer /> */}
                    </LoginLayout> 
                  } />
                  
                  <Route path='/register-email' render={(props) => 
                    <LoginLayout>
                        <RegisterEmail {...props} />
                        {/* <Footer /> */}
                    </LoginLayout> 
                  } />

                  <Route path='/verify-register-email' render={(props) => 
                    <LoginLayout>
                        <VerifyRegister {...props} />
                        {/* <Footer /> */}
                    </LoginLayout> 
                  } />

                  {/* <Route path='/register-celeb' render={(props) => 
                    <LoginLayout>
                        <RegisterCelebPage {...props} />
                        <Footer />
                    </LoginLayout> 
                  } /> */}
                  
                  <Route path='/invited-creator-register' render={(props) => 
                    <InvitedCreatorRegister {...props} />
                  } />

                  <Route path='/successRegisterCelebSkip' render={(props) => 
                    <LoginLayout>
                        <SuccessSkipRegisterCelebPage {...props} />
                        {/* <Footer /> */}
                    </LoginLayout> 
                  } />
                  
                  <Route path='/invitation' render={(props) => 
                    <InvitedCreatorRegisterLanding {...props} />
                  } />
                  
                  <Route path='/successRegisterCeleb' render={(props) => 
                    <LoginLayout>
                        <SuccessRegisterCeleb {...props} />
                        {/* <Footer /> */}
                    </LoginLayout> 
                  } />
                  
                  <Route path='/successLogin' render={(props) => 
                    <LoginLayout>
                        <SuccessLogin {...props} />
                        {/* <Footer /> */}
                    </LoginLayout> 
                  } />

                  <Route path='/resetPass' render={(props) => 
                    <LoginLayout>
                        <ForgotPassword {...props} />
                        {/* <Footer /> */}
                    </LoginLayout> 
                  } />
                  
                  <Route path='/verifyPass' render={(props) => 
                    <LoginLayout>
                        <VerifyForgotPassword {...props} />
                        {/* <Footer /> */}
                    </LoginLayout> 
                  } />
                  
                  <Route path='/connect' render={(props) => 
                    <>
                        <ConnectCampaign {...props} />
                        {/* <Footer /> */}
                    </> 
                  } />
                  
                  <Route path='/connect-success' render={(props) => 
                    <>
                        <ConnectCampaignSuccess {...props} />
                        {/* <Footer /> */}
                    </> 
                  } />
                  
                  <Route path='/successChangePass' render={(props) => 
                    <PopUpLogin {...props} mode="pass" />
                  } />
                  
                  <Route path='/successRegister' render={(props) => 
                    <PopUpLogin {...props} mode="register" />
                  } />

                  <Route path='/contact-us' render={(props) => 
                    <PopUpContact {...props} />
                  } />

                  <Route path='/creator/rating-job/:id' render={(props) => 
                    <div>
                        <Creator {...props} />
                    </div> 
                  } />

                  <Route path='/creator' render={(props) => 
                    <div>
                        <Creator {...props} />
                        <Footer />
                    </div> 
                  } />

                  <Route path='/download-apps' render={(props) => 
                    <div>
                        <DownloadApps {...props} />
                        <Footer />
                    </div> 
                  } />

                  <Route path='/sso' render={(props) => 
                      <div>
                          <RedirectSSO {...props} />
                      </div> 
                    } /> 
                                    
                  <Route path='/notif' render={(props) => 
                    <LoginLayout>
                        <Notif {...props} />
                        {/* <Footer /> */}
                    </LoginLayout> 
                  } />
                  
                  <Redirect to="/creator/home" />

                </Switch>
                </Suspense>
              </AppLayout>
            </Router>
          </React.Fragment>
        </IntlProvider>
      </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      lang: state.lang
  }
}

export default connect(mapStateToProps)(App);
