/* Languange EN */

var enMonetize = {  

    //Submit
    "monetize.submit.title"               : "Connect your Instagram Account",
    "monetize.submit.desc"                : "Go beyond ordinary and lorem ipsum because product designer is tired tell a tale",
    "monetize.submit.opt1title"           : "Your data is safe with us",
    "monetize.submit.opt1desc"            : "We encrypted your data so it will be safe inside BintanGO",
    "monetize.submit.opt2title"           : "Zero privacy breach",
    "monetize.submit.opt2desc"            : "We fetch data from your approval only, no leaked data.",
    "monetize.submit.opt3title"           : "BintanGO is you",
    "monetize.submit.opt3desc"            : "We grow with you to infinity and beyond. Let’s grow together!",
    "monetize.submit.opt3desc"            : "We grow with you to infinity and beyond. Let’s grow together!",
    "monetize.submit.checkIGBiz"          : "My Instagram account is a Instagram Business Account or Instagram Creator Account",
    "monetize.submit.checkIGConFB"        : "My Instagram account is linked into Facebook Page",
    "monetize.submit.checkTC"             : "I agree on Facebook ",
    "monetize.submit.checkTCLink"         : "Terms of Service",
    "monetize.submit.btn"                 : "AGREE AND CONTINUE",

    //Success
    "monetize.success.title"              : "Account Connected!",
    "monetize.success.desc"               : "Now you can continue exploring BintanGO",
    "monetize.success.connect"            : "Connected",
    "monetize.success.btn"                : "CONTINUE",

    //Failed
    "monetize.failed.title"               : "Failed to Connected",
    "monetize.failed.desc"                : "Sory we can’t connect your account because you didn’t give the permission",
    "monetize.failed.btn"                 : "TRY AGAIN",
    "monetize.failed.close"               : "CLOSE",
};

export default enMonetize;
