/* Languange EN */

var enUS = {
  /* 00.Example */
  
  
  /* 01.General */
  "general.showAll"         : "Show All",
  "general.category"        : "Category",
  "general.showMore"        : "Show More",
  "general.of"              : "of",
  "general.or"              : "or",
  "general.tryAgain"        : "TRY AGAIN",
  "general.close"           : "CLOSE",
  "general.cancel"          : "CANCEL",
  "general.connect"         : "CONNECT",
  "general.disconnect"      : "DISCONNECT",
  "general.disconnectCapt"  : "Disconnect",
  "general.edit"            : "EDIT",
  "general.prev"            : "PREVIOUS",
  "general.submit"          : "SUBMIT",
  "general.later"           : "LATER",
  "general.skip"            : "SKIP",
  "general.continue"        : "CONTINUE",
  "general.select"          : "SELECT",
  "general.next"            : "NEXT",
  "general.reset"           : "RESET",
  "general.notif"           : "Notification",
  "general.notifNullTitle"  : "No Notification yet",
  "general.notifNullDesc"   : "we will update the latest info from bintanGO, please check your notification periodically",

  //Video
  "general.video.share"       : "Share",
  "general.video.download"    : "Download",
  "general.video.report"      : "Report",
  "general.video.reasonTitle" : "Reason to report video ",
  "general.video.reasonBtn"   : "Report",

  //Celeb
  "general.celeb.with"        : " with ",
  "IDR"                       : "IDR ",

  //Form Error Validation
  "errMsgReq"                 : "Required.",
  "errMsgEmail"               : "Email is not valid.",

  // FANS PAGE 
  "fans.brand"                : "EXPLORE BRAND DEALS",
  "fans.analytic"             : "SEE MY ANALYTICS",

  // CONTACT 
  "contact.title"             : "Don’t hesitate to contact us! 🤙.",
  "contact.desc"              : "Ask us anything and we will solve your problem as fast as a rocket 🚀",
  "contact.email"             : "EMAIL US",
  "contact.chat"              : "CHAT WITH US",
}; 

export default enUS;
