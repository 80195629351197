/* Languange ID */

var idFooter = {
  "footer.title.allInOne"         : "All-in-One CreatorSpace",
  "footer.desc.allInOne"          : "Download aplikasinya sekarang untuk mulai dari kesempatan kolaborasi setiap minggu, berinteraksi dengan komunitas kreator yang suportif, hingga analisa media sosial yang terintegrasi.",
  "footer.getItOn"                : "Get it on",
  "footer.information"            : "Informasi",
  "footer.menu.helpCenter"        : "Help Center",
  "footer.menu.aboutUs"           : "Tentang Kami",
  "footer.menu.terms"             : "Syarat & Ketentuan",
  "footer.menu.privacy"           : "Kebijakan Privasi",
  "footer.menu.contactUs"         : "Contact Us",

  "footer.menu.forCreators"       : "For Creators",
  "footer.menu.forBusiness"       : "For Business",
  "footer.menu.forFans"           : "For Fans",
  "footer.menu.career"            : "Karir",
  "footer.menu.blogs"             : "Blog",

  //Footer Get Apps
  "footer.gApp.title"             : "Already have a jobs?",  
  "footer.gApp.desc"              : "Relax, you can check your job history through the application. Let's download the apps",  
  "footer.gApp.btn"               : "GET APPS",  
  };
  
export default idFooter;