/* Languange ID */

var idID = {
  /* 00.Example */
  
  
  /* 01.General */
  "general.showAll"         : "Lihat Semua",
  "general.category"        : "Kategori",
  "general.showMore"        : "Tampilkan Lebih Banyak",
  "general.or"              : "Atau",
  "general.of"              : "dari",
  "general.tryAgain"        : "COBA LAGI",
  "general.close"           : "TUTUP",
  "general.cancel"          : "CANCEL",
  "general.connect"         : "CONNECT",
  "general.disconnect"      : "DISCONNECT",
  "general.disconnectCapt"  : "Disconnect",
  "general.edit"            : "EDIT",
  "general.prev"            : "PREVIOUS",
  "general.submit"          : "SUBMIT",
  "general.later"           : "LATER",
  "general.skip"            : "SKIP",
  "general.continue"        : "CONTINUE",
  "general.select"          : "SELECT",
  "general.next"            : "NEXT",
  "general.reset"           : "RESET",
  "general.notif"           : "Notifikasi",
  "general.notifNullTitle"  : "No Notification yet",
  "general.notifNullDesc"   : "we will update the latest info from bintanGO, please check your notification periodically",

  //Video
  "general.video.share"       : "Bagikan",
  "general.video.download"    : "Download",
  "general.video.report"      : "Laporkan",
  "general.video.reasonTitle" : "Alasan laporkan video ",
  "general.video.reasonBtn"   : "Laporkan",

  //Celeb
  "general.celeb.with"        : " dengan ",
  "IDR"                       : "Rp ",
  
  //Form Error Validation
  "errMsgReq"                 : "Required.",
  "errMsgEmail"               : "Email tidak valid.",

  // FANS PAGE 
  "fans.brand"                : "EXPLORE BRAND DEALS",
  "fans.analytic"             : "SEE MY ANALYTICS",

  // CONTACT 
  "contact.title"             : "Don’t hesitate to contact us! 🤙.",
  "contact.desc"              : "Ask us anything and we will solve your problem as fast as a rocket 🚀",
  "contact.email"             : "EMAIL US",
  "contact.chat"              : "CHAT WITH US",
};

export default idID;
