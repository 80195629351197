/* Languange EN */

var enFooter = {
  "footer.title.allInOne"         : "All-in-One CreatorSpace",
  "footer.desc.allInOne"          : "Download BintanGO to start your creator Journey. With a new collaboration opportunity every week, a supportive creator community, and integrated social media analytics, we’ll be sure to have something for you.",
  "footer.getItOn"                : "Get it on",
  "footer.information"            : "Information",
  "footer.menu.helpCenter"        : "Help Center",
  "footer.menu.aboutUs"           : "About Us",
  "footer.menu.terms"             : "Terms of Service",
  "footer.menu.privacy"           : "Privacy Policy",
  "footer.menu.contactUs"         : "Contact Us",

  "footer.menu.forCreators"       : "For Creators",
  "footer.menu.forBusiness"       : "For Business",
  "footer.menu.forFans"           : "For Fans",
  "footer.menu.career"            : "Career",
  "footer.menu.blogs"             : "Blogs",  

  //Footer Get Apps
  "footer.gApp.title"             : "Already have a jobs?",  
  "footer.gApp.desc"              : "Relax, you can check your job history through the application. Let's download the apps",  
  "footer.gApp.btn"               : "GET APPS",  
  };
  
export default enFooter;