/* Languange ID */

var idBrandDeals = {  
    // Nav Bar 
    "brandDeals.nav.title"              : "Comment Job",
    
    // Brand Deals Job 
    "brandDeals.job.commentTitle"       : "Let’s comment on this post",
    "brandDeals.job.commentDesc1"       : "comment this post by go to ",
    "brandDeals.job.commentDesc2"       : " Instagram post, click the button below",
    "brandDeals.job.visiPost"           : "VISIT POST",
    "brandDeals.job.est"                : "est. earning",
    "brandDeals.job.quota"              : "Quota for this jobs",
    "brandDeals.job.quotaAv"            : " Quota Available",
    "brandDeals.job.rangeJob"           : "Job will close at ",
    "brandDeals.job.creatorQualTitle"   : "Creator Qualification",
    "brandDeals.job.showImgPost"        : "Preview Post",
    "brandDeals.job.hideImgPost"        : "Hide Post",
    "brandDeals.job.hastag"             : "Make sure you include the hashtags & keywords as written above in your comment to get paid",
    
    // Brand Deals AppliedJob 
    "brandDeals.jobApplied.commentPost"             : "💬 Comment Post",
    "brandDeals.jobApplied.applied"                 : "apply at",
    "brandDeals.jobApplied.status_APPLIED"          : "APPLIED",
    "brandDeals.jobApplied.status_FAILED"           : "FAILED",
    "brandDeals.jobApplied.status_COMPLETED"        : "COMPLETED",
    "brandDeals.jobApplied.status_WATING_PAYMENT"   : "WATING PAYMENT",
    "brandDeals.jobApplied.msg_APPLIED"             : "It Usually takes one day for us to review your comment, we will let you know if is it done.",
    "brandDeals.jobApplied.msg_FAILED"              : "Something wrong, we can’t find your comment. Let’s submit manually your comment to get the reward",
    "brandDeals.jobApplied.msg_COMPLETED"           : "Your payment was successfully sent on 1 Jun 2022, let's check it on earning page",
    "brandDeals.jobApplied.msg_WATING_PAYMENT"      : "We’ve already verified you comment, and please kindly wait for the payment",
    "brandDeals.jobApplied.jobQuestion"             : "Any question for this jobs?",
    "brandDeals.jobApplied.estEarn"                 : "Est. Earning",
    "brandDeals.jobApplied.contact"                 : "Contact Us",
    "brandDeals.jobApplied.dispute"                 : "REQUEST DISPUTE",
    "brandDeals.jobApplied.jobDetail"               : "Jobs Detail",

    // Brand Deals Job Carousel
    "brandDeals.job.taskTitle"          : "Cara menyelesaikan tugas",
    "brandDeals.job.taskDesc"           : "Ayo dilihat langkah untuk menyelesaikan tugas ini",
    "brandDeals.job.task1Title"         : "Kunjungi Post",
    "brandDeals.job.task1Desc"          : "Kunjungi postingan dengan menekan “Lihat Post”",
    "brandDeals.job.task2Title"         : "Mulai Komen",
    "brandDeals.job.task2Desc"          : "Buat komenmu dengan mengikuti instruksi diatas",
    "brandDeals.job.task3Title"         : "Klik saja",
    "brandDeals.job.task3Desc"          : "Klik tombol “Selesai Komen” agar kita tau kamu sudah komen ",
    "brandDeals.job.task4Title"         : "Dapatkan Hadiahnya",
    "brandDeals.job.task4Desc"          : "Sambil kita cek, kamu tinggal menunggu!",

    // Brand Deals Job Error Page
    "brandDeals.job.notFillTitle"       : "Sorry, some of your data is still not filled in",
    "brandDeals.job.notFillDesc"        : "let’s complete your data profile, so you can get a change to apply to this jobs",
    "brandDeals.job.notFillBtn"         : "COMPLETE MY PROFILE",
    "brandDeals.job.notQualTitle"       : "Sorry, you are not qualified for this jobs",
    "brandDeals.job.notQualDesc"        : "it seems you some of the requirement for this jobs not suit with you, le’s find another jobs",
    "brandDeals.job.notQualBtn"         : "EXPLORE OTHERS JOBS",

    // Brand Deals Job Comment
    "brandDeals.jobCmt.title"           : "What you should comment in this post",
    "brandDeals.jobCmt.subsTitle"       : "⚠️ Hashtags & Keywords",
    "brandDeals.jobCmt.subsDesc1"       : "You ",
    "brandDeals.jobCmt.subsDesc2"       : "MUST",
    "brandDeals.jobCmt.subsDesc3"       : " include these hasthags and keywords",

    // Brand Deals Detail
    "brandDeals.detail.platform"        : "Platform",
    "brandDeals.detail.reqGuest"        : "let’s check the requirement",
    "brandDeals.detail.reqLogin"        : "Woohoo, you meet {req} requirements needed",
    "brandDeals.detail.reqLoginNC"      : "You need to complete some of the information",
    "brandDeals.detail.reqLoginFufill"  : "Woohoo, you match with the requirement",
    "brandDeals.detail.qualCategory"    : "Category",
    "brandDeals.detail.qualGender"      : "Gender",
    "brandDeals.detail.qualLocation"    : "Location",
    "brandDeals.detail.qualAge"         : "Age",
    "brandDeals.detail.qualFollowers"   : "Followers",
    "brandDeals.detail.qualSubs"        : "Subscribers",
    "brandDeals.detail.qualMandatory"   : "Mandatory Requirement",
    "brandDeals.detail.qualOptional"    : "Optional Requirement",
    "brandDeals.detail.qualYou"         : "You: ",
    "brandDeals.detail.qualReq"         : "Req: ",
    "brandDeals.detail.qualCompProfile" : "Complete your profile",
    "brandDeals.detail.qualCompCat"     : "Complete Category",
    "brandDeals.detail.qualConnIG"      : "Connect Instagram",
    "brandDeals.detail.qualConnTwitter" : "Connect Twitter",
    "brandDeals.detail.qualConnYoutube" : "Connect Youtube",
    "brandDeals.detail.qualConnTiktok"  : "Connect TikTok",
    "brandDeals.detail.qualConnected"   : "Connected",
    "brandDeals.detail.timelineTitle"   : "Campaign Timeline",
    "brandDeals.detail.timelineDesc"    : "Lakukan kolaborasi tanpa pusing, tinggal mengikuti timeline ini ya!",
    "brandDeals.detail.periodRegister"  : "Registration Period",
    "brandDeals.detail.periodCampaign"  : "Campaign Period",
    "brandDeals.detail.periodPayment"   : "Payment Period",
    "brandDeals.detail.brief"           : "See Brief",
    "brandDeals.detail.quota"           : "Quota for this campaign",
    "brandDeals.detail.kolNeeded"       : " KOL Needed",
    "brandDeals.detail.aboutCampaign"   : "About the Campaign",
    "brandDeals.detail.aboutClient"     : "About Client",

    // Brand Deals Detail Task
    "brandDeals.dTask.draftDeadline"   : "Drafting Submission",
    "brandDeals.dTask.deadline"        : "Deadline ",
    "brandDeals.dTask.postPeriod"      : "Posting Period",
    "brandDeals.dTask.submitProof"     : "Submit Proof of Work",
    "brandDeals.dTask.insightProof"    : "Submit Insight Proof",

    // Brand Deals Brief
    "brandDeals.brief.navTitle"         : "Campaign Brief",
    "brandDeals.brief.title"            : "Campaign Overview",
    "brandDeals.brief.referenceTitle"   : "Reference",
    "brandDeals.brief.captionTitle"     : "Caption Creation",
    "brandDeals.brief.captionLocked"    : "Yahhh, masih dikunci, akan dibuka ketika kamu sudah apply dan ter-approved",
    "brandDeals.brief.sowTitle"         : "Brief SOW for",
    "brandDeals.brief.sowStartDate"     : "Work start on ",
    "brandDeals.brief.sowEndDate"       : "SOW will end on ",
    "brandDeals.brief.locked"           : "Sorry, the brief is still not available",

    // Brand Deals Footer
    "brandDeals.footer.download"        : "Download BintanGO",
    "brandDeals.footer.job"             : "Jadilah orang pertama yang mendapatkan pekerjaan ini, daftar melalui aplikasi!",
    "brandDeals.footer.jobApplied"      : "Ayo cek dan daftar jobsnya lewat applikasi",
    "brandDeals.footer.detail"          : "Jadilah orang pertama yang mendapatkan pekerjaan ini, daftar melalui aplikasi!",
    "brandDeals.footer.detailApplied"   : "Ayo lihat peluang kerja lain sambil nunggu di-approve!",
    "brandDeals.footer.detailApprove"   : "Sederhanakan pekerjaanmu melalui aplikasi kami!",
    "brandDeals.footer.detailReview"    : "Ayo lihat peluang kerja lain sambil nunggu di-approve!",
    "brandDeals.footer.detailReject"    : "Peluang lain menunggumu dalam aplikasi kami!",
    "brandDeals.footer.appliedWaiting"  : "Dapatkan lebih banyak kolaborasi melalui aplikasi kami",
    "brandDeals.footer.appliedApproved" : "Sederhanakan pekerjaanmu melalui aplikasi kami!",
    "brandDeals.footer.briefApplied"    : "Yuk kerjain tugasnya lewat aplikasi",

    // Brand Deals Detail Task
    "brandDeals.status.statusTitle"     : "Campaing Status",
    
    "brandDeals.status.statusApplied"   : "APPLIED",
    "brandDeals.status.statusRevivew"   : "CLIENT REVIEW",
    "brandDeals.status.statusApproved"  : "APPROVED",
    "brandDeals.status.statusOngoing"   : "ONGOING",
    "brandDeals.status.statusCompleted" : "COMPLETED",
    "brandDeals.status.statusRejected"  : "REJECTED",

    "brandDeals.status.appliedDesc"     : "We will announce the results 5 - 7 working days after registration for this campaign closes",
    "brandDeals.status.reviewDesc"      : "Yey, your profile is being checked by the client. please wait 3-5 days to get results",
    "brandDeals.status.approvedDesc"    : "Yohoo, you got approved. let’s check & do your task",
    "brandDeals.status.ongoingDesc"     : "Let's do the task according to the date and brief that has been determined",
    "brandDeals.status.completedDesc"   : "Yey, you already complete all your task for this campaign",
    "brandDeals.status.rejectedNotes"   : "Campaing Status",
    
    "brandDeals.status.payment"         : "Payment",
    "brandDeals.status.paymentLater"    : "Later Payment",
    "brandDeals.status.paymentUpFront"  : "Upfront Payment",
    "brandDeals.status.paymentSplit"    : "Split Payment",
    "brandDeals.status.paymentSelf"     : "Your Payment",
    "brandDeals.status.paymentFirst"    : "First Payment",
    "brandDeals.status.paymentSecond"   : "Second Payment",
    "brandDeals.status.paymentSplitDsc1": "Approximately {date} you will recieved your first payment",
    "brandDeals.status.paymentSplitDsc2": "Approximately {date} you will recieved your second payment",
    "brandDeals.status.paymentLaterDesc": "Approximately {date} you will received your payment",
    "brandDeals.status.paymentUpFrtDesc": "Approximately {date} you will received your payment upfront payment",
    "brandDeals.status.paymentFinished" : "Received at {date}",
};

export default idBrandDeals;
